import React, { useEffect, useState } from 'react'
import data from '../data/product.json'
import sembakoData from '../data/sembako.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome, faBell, faUser, faCartPlus, faPlus, faBagShopping, faPlayCircle, faQrcode, faBook, faPhoneVolume, faGamepad, faShoppingBag, faWallet, faBorderAll, faArrowAltCircleRight, faArrowRight, faGear, faClover, faHeart, faTrash} from '@fortawesome/free-solid-svg-icons'
import Cart from '../component/Cart'
import Modal from 'react-modal'
import Swal from 'sweetalert2'



function Home({ formatRupiah }) {
    
    // State untuk menentukan apakah modal keranjang sedang ditampilkan atau tidak
    const [isModalOpen, setIsModalOpen] = useState(false);
    

    // Fungsi untuk membuka dan menutup modal keranjang
    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };

    const handleClick = () => {
      if (count === 0) {
        // Tampilkan SweetAlert jika count === 0
        Swal.fire({
          icon: 'info',
          title: 'Cart is Empty',
          text: 'Add items to the cart before proceeding.',
        });
      } else {
        // Panggil toggleModal jika count > 0
        toggleModal();
      }
    };
    const [count, setItemCount] = useState(0);

    const saveCartToLocalStorage = (foodCart, sembakoCart) => {
      try {
        // Save foodCartItems to local storage
        const foodCartJson = JSON.stringify(foodCart);
        localStorage.setItem('foodCart', foodCartJson);
    
        // Save sembakoCartItems to local storage
        const sembakoCartJson = JSON.stringify(sembakoCart);
        localStorage.setItem('sembakoCart', sembakoCartJson);
    
        // Update the state with the new count
        const itemCount = foodCart.length + sembakoCart.length;
        setItemCount(itemCount);
    
        console.log('Cart data saved to local storage');
        console.log('Item count:', itemCount); // Display the count
      } catch (error) {
        console.error('Error saving cart data to local storage:', error.message);
      }
    };
    
    
    useEffect(() => {
      // Retrieve foodCartItems from localStorage
      const storedFoodCartData = localStorage.getItem('foodCart');
      if (storedFoodCartData) {
        setFoodCartItems(JSON.parse(storedFoodCartData));
      }
    
      // Retrieve sembakoCartItems from localStorage
      const storedSembakoCartData = localStorage.getItem('sembakoCart');
      if (storedSembakoCartData) {
        setSembakoCartItems(JSON.parse(storedSembakoCartData));
      }
    }, []);

// Retrieve cart data from localStorage
const storedCartData = localStorage.getItem('cart');

// Parse the JSON data (assuming it's stored as JSON)
const parsedCartData = JSON.parse(storedCartData);

// Get the count of items in the cart
const itemCount = parsedCartData ? parsedCartData.length : 0;



  // Update the initial state of products and sembako to include a type and use unique ids
  const [products, setProducts] = useState([]);
  useEffect(() => {
    setProducts(data.products.slice(0, 5).map(product => ({ ...product, type: 'product' })));
  }, []);

  const [sembako, setSembako] = useState([]);
  useEffect(() => {
    setSembako(sembakoData.sembako.slice(0, 5).map(product => ({ ...product, type: 'sembako' })));
  }, []);

  const [foodCartItems, setFoodCartItems] = useState([]);
  const [sembakoCartItems, setSembakoCartItems] = useState([]);

  const updateCartItem = (cartType, index, updatedCount) => {
    if (cartType === 'food') {
      setFoodCartItems((prevCartItems) => {
        const newCartItems = [...prevCartItems];
        newCartItems[index].count = updatedCount;
        return newCartItems;
      });
    } else if (cartType === 'sembako') {
      setSembakoCartItems((prevCartItems) => {
        const newCartItems = [...prevCartItems];
        newCartItems[index].count = updatedCount;
        return newCartItems;
      });
    }
  };

  const addCart = (productType, product) => {
    const existingItemIndex =
      productType === 'food'
        ? foodCartItems.findIndex((item) => item.id === product.id && item.type === product.type)
        : sembakoCartItems.findIndex((item) => item.id === product.id && item.type === product.type);
  
    if (existingItemIndex !== -1) {
      // If product exists, increment count
      updateCartItem(productType, existingItemIndex, productType === 'food' ? foodCartItems[existingItemIndex].count + 1 : sembakoCartItems[existingItemIndex].count + 1);
    } else {
      // If product doesn't exist, add as a new item
      if (productType === 'food') {
        setFoodCartItems((prevCartItems) => [...prevCartItems, { ...product, count: 1 }]);
      } else if (productType === 'sembako') {
        setSembakoCartItems((prevCartItems) => [...prevCartItems, { ...product, count: 1 }]);
      }
    }
  };
  
  const handleIncrement = (cartType, index) => {
    if (cartType === 'food') {
      setFoodCartItems((prevCartItems) => {
        const newCartItems = [...prevCartItems];
        newCartItems[index] = { ...newCartItems[index], count: newCartItems[index].count + 1 };
        return newCartItems;
      });
    } else if (cartType === 'sembako') {
      setSembakoCartItems((prevCartItems) => {
        const newCartItems = [...prevCartItems];
        newCartItems[index] = { ...newCartItems[index], count: newCartItems[index].count + 1 };
        return newCartItems;
      });
    }
  };
  
  
  const handleDecrement = (cartType, index) => {
    if (cartType === 'food') {
      setFoodCartItems((prevCartItems) => {
        const newCartItems = [...prevCartItems];
        if (newCartItems[index].count > 1) {
          newCartItems[index] = { ...newCartItems[index], count: newCartItems[index].count - 1 };
        }
        return newCartItems;
      });
    } else if (cartType === 'sembako') {
      setSembakoCartItems((prevCartItems) => {
        const newCartItems = [...prevCartItems];
        if (newCartItems[index].count > 1) {
          newCartItems[index] = { ...newCartItems[index], count: newCartItems[index].count - 1 };
        }
        return newCartItems;
      });
    }
  };

  const deleteCart = (cartType, item) => {
    if (cartType === 'food') {
      setFoodCartItems((prevCartItems) => {
        const updatedCartItems = prevCartItems.filter((cartItem) => cartItem.id !== item.id);
        saveCartToLocalStorage(updatedCartItems, sembakoCartItems); // Perbarui penyimpanan lokal dengan kedua keranjang
        return updatedCartItems;
      });
    } else if (cartType === 'sembako') {
      setSembakoCartItems((prevCartItems) => {
        const updatedCartItems = prevCartItems.filter((cartItem) => cartItem.id !== item.id);
        saveCartToLocalStorage(foodCartItems, updatedCartItems); // Perbarui penyimpanan lokal dengan kedua keranjang
        return updatedCartItems;
      });
    }
  };
  
  
  
  
  useEffect(() => {
    // Hanya simpan ke local storage jika foodCartItems atau sembakoCartItems tidak kosong
    if (foodCartItems.length > 0 || sembakoCartItems.length > 0) {
      saveCartToLocalStorage(foodCartItems, sembakoCartItems);
    }
  }, [foodCartItems, sembakoCartItems]);
  

  return (
 <>
    

        <nav className="bg-gradient-to-r from-blue-600 to-blue-800 w-full p-4 shadow-md fixed top-0">
            <ul className="flex justify-between gap-4 text-white items-center">
                <li className='md:hidden'><FontAwesomeIcon icon={faHome} className='text-xl' /></li>
                <li className='w-1/6 md:block hidden'><p className="text-2xl font-bold">TOKO ALFINAS</p></li>
                <li className=' md:block hidden'>Kategori</li>
                <li className="w-full"><input type="text" className="w-full p-1 rounded-lg" placeholder="Cari Produk ...." /></li>

                <li><FontAwesomeIcon icon={faBell} className='text-xl' /></li>
                <li className=' md:block hidden'></li>

                <li className=' md:block hidden'><button className='bg-white p-2 text-blue-600 rounded-lg'>Masuk</button></li>
                <li className=' md:block hidden'><button className='bg-white p-2 text-blue-600 rounded-lg'>Daftar</button></li>



            </ul>
        </nav>
<div className="flex">
<div className="hidden md:block w-1/3 mt-16 bg-gray-200">
    <div className="bg-white m-4 rounded-xl h-80">
    <p className='font-bold text-center p-4'>KATEGORI</p>
    
    </div>
</div>
<div className="w-full mt-16 bg-gray-200">

<div className="">
<img src="https://images.tokopedia.net/img/cache/1208/NsjrJu/2024/2/19/060795c4-d6c0-4475-976d-a855a6be10b8.jpg.webp?ect=4g" className="w-full object-cover md:p-4 md:rounded-2xl" />
</div>
<div className="bg-gray-200">
    <div className="p-4 bg-white mt-2 md:hidden">
        

        <div className="flex grid grid-cols-5 gap-2">

        
        <div className="flex flex-col items-center justify-center text-center">
        <div className="flex items-center justify-center rounded-full w-12 h-12 bg-blue-600 hover:bg-blue-400">
                <FontAwesomeIcon icon={faPhoneVolume} className="text-white text-2xl" />  
            </div>
            <p className="text-sm">Tagihan</p>
        </div>

        <div className="flex flex-col items-center justify-center text-center">
        <div className="flex items-center justify-center rounded-full w-12 h-12 bg-blue-600 hover:bg-blue-400">
                <FontAwesomeIcon icon={faGamepad} className="text-white text-2xl" />  
            </div>
            <p className="text-sm">Top Up</p>
        </div>

        <div className="flex flex-col items-center justify-center text-center">
        <div className="flex items-center justify-center rounded-full w-12 h-12 bg-blue-600 hover:bg-blue-400">
                <FontAwesomeIcon icon={faShoppingBag} className="text-white text-2xl" />  
            </div>
            <p className="text-sm">Mall</p>
        </div>

        <div className="flex flex-col items-center justify-center text-center">
        <div className="flex items-center justify-center rounded-full w-12 h-12 bg-blue-600 hover:bg-blue-400">
                <FontAwesomeIcon icon={faWallet} className="text-white text-2xl" />  
            </div>
            <p className="text-sm">Payment</p>
        </div>



        <div className="flex flex-col items-center justify-center text-center">
        <div className="flex items-center justify-center rounded-full w-12 h-12 bg-blue-600 hover:bg-blue-400">
                <FontAwesomeIcon icon={faBorderAll} className="text-white text-2xl" />  
            </div>
            <p className="text-sm">Semua</p>
        </div>
        
        
        
        

        
        
        
        </div>

    </div>


    
    <div className="flex w-full justify-between items-center">
    <p className='font-bold m-4 text-blue-600'>Alfinas Food</p>
    <p className='text-sm m-4 text-gray-500'>Lihat Selengkapnya <FontAwesomeIcon icon={faArrowRight} /></p>
    </div>
    <div className="mr-4 ml-4 grid grid-cols-2 md:grid-cols-5 gap-2 items-center justify-between">
        {products.map((product, index)=> (
            <div key={index} className="w-full bg-white shadow-md p-2 rounded-xl">
            <img src={product.imageUrl} className="h-40 w-full object-cover rounded-2xl " />
            <p className="mt-2 font-medium">{product.nama}</p>
            <p className="text-md">{formatRupiah(product.price)}</p>
            <span className="text-sm text-gray
            
            -500">Sisa: {product.qty} pcs</span>
            <div className="flex justify-between items-center">
            <button onClick={() => addCart('food', product)} className="flex m-1 p-1 w-full bg-blue-600 hover:bg-blue-800 rounded-lg text-white items-center justify-center">
  <FontAwesomeIcon icon={faCartPlus} className="pr-1"/> Keranjang
</button>
            <button className="m-1 p-1 w-1/4 bg-red-600 rounded-lg text-white hover:bg-red-800"><FontAwesomeIcon icon={faHeart} /></button>
            </div>
        </div>
        
        ))}
    </div>


    <div className="flex w-full justify-between items-center">
    <p className='font-bold m-4 text-blue-600'>Alfinas Grosir</p>
    <p className='text-sm m-4 text-gray-500'>Lihat Selengkapnya <FontAwesomeIcon icon={faArrowRight} /></p>
    </div>
    <div className="mr-4 ml-4 mb-20 grid grid-cols-2 md:grid-cols-5 gap-4 items-center justify-between">
    {sembako.map((product, index) => (
            <div key={index} className="w-full bg-white shadow-md p-2 rounded-xl">
                <img src={product.imageUrl} className="h-40 w-full object-cover rounded-2xl " />
                <p className="mt-2 font-medium">{product.nama}</p>
                <p className="text-md">{formatRupiah(product.price)}</p>
                <span className="text-sm text-gray
                
                -500">Sisa: {product.qty} pcs</span>
            <div className="flex justify-between items-center">
            <button onClick={() => addCart('sembako', product)} className="flex m-1 p-1 w-full bg-blue-600 hover:bg-blue-800 rounded-lg text-white items-center justify-center">
  <FontAwesomeIcon icon={faCartPlus} className="pr-1"/> Keranjang
</button>
            <button className="m-1 p-1 w-1/4 bg-red-600 rounded-lg text-white hover:bg-red-800"><FontAwesomeIcon icon={faHeart} /></button>
            </div>
            </div>
        
        ))}
    </div>

    </div>    
<nav className="md:hidden bg-white w-full p-2 fixed bottom-0 left-0 right-0 shadow-2xl" style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }}>
            <ul className="flex justify-between gap-4 text-blue-600 items-center">
                <li className="text-center flex flex-col items-center">
                    <FontAwesomeIcon icon={faHome} size='xl' />
                    <p className="text-sm">Home</p>
                </li>
                <li className="text-center flex flex-col items-center">
                    <FontAwesomeIcon icon={faUser} size='xl' />
                    <p className="text-sm">Profile</p>
                </li>
      {/* Tombol untuk membuka modal keranjang */}
      <li
        className='bg-gradient-to-r from-blue-600 to-blue-800 absolute rounded-full w-20 h-20 flex items-center justify-center left-1/2 transform -translate-x-1/2 bottom-2 border-solid border-8 border-white shadow-2xl  transition duration-300 ease-in-out'
        onClick={handleClick} // Panggil fungsi toggleModal saat tombol diklik
      >
        <li className="text-center flex flex-col items-center">
          <FontAwesomeIcon icon={faCartPlus} size='xl' className="text-white" />
          <p className="text-sm text-white ">Cart {count > 0 ? `(${count})` : ''}</p>
        </li>
      </li>
    
    <li className="text-center flex flex-col items-center m-6"></li>
                <li className="text-center flex flex-col items-center">
                    <FontAwesomeIcon icon={faQrcode} size='xl' />
                    <p className="text-sm">QRIS</p>
                </li>
                <li className="text-center flex flex-col items-center relative">
                    <FontAwesomeIcon icon={faGear} size='xl' />
                    <p className="text-sm">Setting</p>
                </li>



            </ul>
        </nav>


</div>
<Modal
  isOpen={isModalOpen}
  onRequestClose={toggleModal}
  contentLabel="Keranjang Modal"
  
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    content: {
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      margin: '0',
      padding: '0',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }}

>

<Cart
  cartItems={foodCartItems}
  handleDecrement={(index) => handleDecrement('food', index)}
  handleIncrement={(index) => handleIncrement('food', index)}
  deleteCart={(item) => deleteCart('food', item)}
  cartType="food" // Specify the cartType for food
  formatRupiah={formatRupiah} // Pass the formatRupiah function

/>


<Cart
  cartItems={sembakoCartItems}
  handleDecrement={(index) => handleDecrement('sembako', index)}
  handleIncrement={(index) => handleIncrement('sembako', index)}
  deleteCart={(item) => deleteCart('sembako', item)}
  cartType="sembako" // Specify the cartType for sembako
  formatRupiah={formatRupiah} // Pass the formatRupiah function

/>




        {/* Tombol untuk menutup modal */}
        <div className="flex items-center justify-center">
        <button onClick={toggleModal} className='w-1/3 text-center  bg-red-600 p-2 m-2 rounded-full text-white'>Tutup</button>
        </div>
      </Modal>
{/* Daftar Keranjang */}
<div className="hidden md:block w-1/3 mt-16 bg-gray-200">

<Cart 
        cartItems={foodCartItems} 
        handleDecrement={(index) => handleDecrement('food', index)} 
        handleIncrement={(index) => handleIncrement('food', index)} 
        deleteCart={(item) => deleteCart('food', item)} 
        cartType="food" // Specify the cartType for sembako
        formatRupiah={formatRupiah} // Pass the formatRupiah function


      />
      <Cart 
        cartItems={sembakoCartItems} 
        handleDecrement={(index) => handleDecrement('sembako', index)} 
        handleIncrement={(index) => handleIncrement('sembako', index)} 
        deleteCart={(item) => deleteCart('sembako', item)} 
        cartType="sembako" // Specify the cartType for sembako
        formatRupiah={formatRupiah} // Pass the formatRupiah function


      />



      </div>
</div>

       
    
 </>
  )
}

export default Home