import React from 'react'
import {useState} from 'react'
import Payment from './component_old/Payment.jsx'
import Pos from './component_old/Pos.jsx'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home.jsx';
import Cart from './component/Cart.jsx';
import Nav from './component/Nav.jsx';
import Swal from 'sweetalert2'




function App() {
  const formatRupiah = (amount) => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    return formatter.format(amount);
  };




    

  return (
    <>
 


    <Router>
    <Nav />
      <Routes>
        <Route path="/cart" element={<Cart />} />
        <Route
  path="/"
  element={<Home formatRupiah={formatRupiah} />}
/>

      </Routes>
    </Router>


    </>
  )
}

export default App