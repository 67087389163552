import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBell, faUser, faCartPlus,faQrcode,faGear } from '@fortawesome/free-solid-svg-icons';

const Nav = ({ handleClick, count }) => {
  return (
    <>
    <nav className="bg-gradient-to-r from-blue-600 to-blue-800 w-full p-4 shadow-md fixed top-0">
      <ul className="flex justify-between gap-4 text-white items-center">
        <li className='md:hidden'><FontAwesomeIcon icon={faHome} className='text-xl' /></li>
        <li className='w-1/6 md:block hidden'>TOKO ALFINAS</li>
        <li className='md:block hidden'>Kategori</li>
        <li className="w-full"><input type="text" className="w-full p-1 rounded-lg" placeholder="Cari Produk ...." /></li>

        <li><FontAwesomeIcon icon={faBell} className='text-xl' /></li>
        <li className=' md:block hidden'></li>

        <li className=' md:block hidden'><button className='bg-white p-2 text-blue-600 rounded-lg'>Masuk</button></li>
        <li className=' md:block hidden'><button className='bg-white p-2 text-blue-600 rounded-lg'>Daftar</button></li>
      </ul>
    </nav>
    
    <nav className="md:hidden bg-white w-full p-2 fixed bottom-0 left-0 right-0 shadow-2xl" style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }}>
            <ul className="flex justify-between gap-4 text-blue-600 items-center">
                <li className="text-center flex flex-col items-center">
                    <FontAwesomeIcon icon={faHome} size='xl' />
                    <p className="text-sm">Home</p>
                </li>
                <li className="text-center flex flex-col items-center">
                    <FontAwesomeIcon icon={faUser} size='xl' />
                    <p className="text-sm">Profile</p>
                </li>
      {/* Tombol untuk membuka modal keranjang */}
      <li
        className='bg-gradient-to-r from-blue-600 to-blue-800 absolute rounded-full w-20 h-20 flex items-center justify-center left-1/2 transform -translate-x-1/2 bottom-2 border-solid border-8 border-white shadow-2xl  transition duration-300 ease-in-out'
        onClick={handleClick} // Panggil fungsi toggleModal saat tombol diklik
      >
        <li className="text-center flex flex-col items-center">
          <FontAwesomeIcon icon={faCartPlus} size='xl' className="text-white" />
          <p className="text-sm text-white ">Cart {count > 0 ? `(${count})` : ''}</p>
        </li>
      </li>
    
    <li className="text-center flex flex-col items-center m-6"></li>
                <li className="text-center flex flex-col items-center">
                    <FontAwesomeIcon icon={faQrcode} size='xl' />
                    <p className="text-sm">QRIS</p>
                </li>
                <li className="text-center flex flex-col items-center relative">
                    <FontAwesomeIcon icon={faGear} size='xl' />
                    <p className="text-sm">Setting</p>
                </li>



            </ul>
        </nav>
        </>
  );
};

export default Nav;
