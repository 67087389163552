import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const getProvinces = async () => {
  try {
    const response = await axios.get('https://pro.rajaongkir.com/api/province', {
      headers: {
        'key': '743d289916d537dee06eaf6a50c8aa42', // Gantilah dengan API key Anda
      },
    });

    console.log(response.data);
    // Lakukan sesuatu dengan data response, seperti menyimpannya dalam state komponen
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};



function Cart({ cartItems, handleDecrement, handleIncrement, deleteCart, cartType, formatRupiah }) {
    const calculateTotal = () => {
        return cartItems.reduce((total, item) => {
          return total + item.price * item.count;
        }, 0);
      };

      useEffect(() => {
        getProvinces();
      }, []);

  return (
<>
  {cartItems.length !== 0 && (
    <div className="bg-white md:m-4 rounded-xl">
      <div className="bg-blue-500 rounded-2xl m-2">
        <p className="font-bold text-center p-4 text-white">
          {cartType === 'food' ? 'Keranjang Makanan' : 'Keranjang Sembako'}
        </p>
      </div>
      {/* Tampilkan daftar produk di keranjang */}
      {cartItems.length === 0 ? (
        <p className="text-center p-2">Keranjang kosong</p>
      ) : (
        cartItems.map((item, index) => (
          <div key={item.id} className="p-4 border-b flex items-center">
            <img
              src={item.imageUrl}
              alt={item.nama} 
              className="h-20 w-20 object-cover border border-md rounded-md mr-4"
            />
            <div className="flex flex-col w-full">
              <p className="font-bold">{item.nama}</p>
              <p className="">{formatRupiah(item.price)}</p>
              <div className="flex items-center">
                {/* Tombol kurang */}
                <button
                  onClick={() => handleDecrement(index)}
                  className="bg-blue-600 text-white p-2 rounded-full hover:bg-blue-800 mr-2"
                >
                  -
                </button>
                {/* Input jumlah */}
                <input
                  type="text"
                  value={item.count}
                  readOnly // Make the input read-only
                  className="w-1/2 border rounded-full text-center"
                />
                {/* Tombol tambah */}
                <button
                  onClick={() => handleIncrement(index)}
                  className="bg-blue-600 text-white p-2 rounded-full hover:bg-blue-800 ml-2"
                >
                  +
                </button>
                {/* Tombol hapus keranjang */}
                <button
                  onClick={() => deleteCart(item)}
                  className="m-1 p-1 w-1/4 bg-red-600 rounded-lg text-white hover:bg-red-800"
                >
                  {/* Import FontAwesomeIcon and faTrash if not already imported */}
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
              
            </div>
          </div>
        ))
      )}
                        <div className="p-4">
                        <p className="font-bold">Total Pesanan: {formatRupiah(calculateTotal())}</p>
                        </div>

    </div>
    
  )}

</>

  );
}

export default Cart;
