import React, { useState, useEffect } from 'react';
// https://github.com/gcoro/react-qrcode-logo/tree/377eee17f3f2b361d5c7a719ab08a7e1a629c533?tab=readme-ov-file
import { QRCode } from 'react-qrcode-logo';


const Payment = ({ totalQty }) => {
  const [qris, setQris] = useState("00020101021126680016ID.CO.TELKOM.WWW011893600898225663129502150001952566312950303UKE51440014ID.CO.QRIS.WWW0215ID10221472351570303UKE5204549953033605502015802ID5913ALFINAS SANDI6010KAB. BOGOR61051662062220511785585435760703A316304262E");
  const [yn, setYn] = useState("y");
  const [fee, setFee] = useState('r');
  const [tax, setTax] = useState("2500");
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  const logoSvg = `
   `;
const logoBase64 = `data:image/svg+xml;base64,${btoa(logoSvg)}`;
let totalan = Number(totalQty) + Number(tax);

  useEffect(() => {
    const handleCalculate = () => {
      if (typeof totalQty !== 'undefined') {
        let uang = `54${totalQty.toString().length.toString().padStart(2, '0')}${totalQty}`;
  
        if (yn === 'y') {
          if (fee === 'r') {
            uang += `5802ID55020256${tax.length.toString().padStart(2, '0')}${tax}`;
          } else if (fee === 'p') {
            uang += `5802ID55020357${tax.length.toString().padStart(2, '0')}${tax}`;
          } else {
            console.error("Pilihan tidak valid");
            return;
          }
        } else {
          uang += "5802ID";
        }
  
        const qrisWithoutCRC = qris.slice(0, -4);
        const step1 = qrisWithoutCRC.replace("010211", "010212");
        const step2 = step1.split("5802ID");
        const fix = `${step2[0]}${uang}${step2[1]}${convertCRC16(step2[0] + uang + step2[1])}`;
  
        // Encode the data for the QR code
        const data = encodeURIComponent(fix);
  
        // URL for Google Charts API to generate QR code
        // const cdnUrl = `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${data}&chf=bg,s,00000000`;
        
        // Set QR code URL
        setQrCodeUrl(fix);
      }
    };

    const convertCRC16 = (str) => {
      let crc = 0xFFFF;
      const strlen = str.length;

      for (let c = 0; c < strlen; c++) {
        crc ^= str.charCodeAt(c) << 8;
        for (let i = 0; i < 8; i++) {
          if (crc & 0x8000) {
            crc = (crc << 1) ^ 0x1021;
          } else {
            crc = crc << 1;
          }
        }
      }

      let hex = crc & 0xFFFF;
      hex = hex.toString(16).toUpperCase();
      if (hex.length === 3) hex = "0" + hex;

      return hex;
    };

    handleCalculate();
  }, [qris, totalQty, yn, fee, tax]);

  return (
<>
<div className="flex flex-col sm:flex-row justify-start items-start mt-4">
  {/* QR Code */}
  <div className="mr-4">
    <QRCode
      value={qrCodeUrl}
      size={180}
      fgColor="#9932CC"
      ecLevel="M"
      qrStyle="squares"
      eyeRadius={[
        [10, 10, 0, 10], // top/left eye
        [10, 10, 10, 0], // top/right eye
        [10, 0, 10, 10], // bottom/left
      ]}
      logoImage={logoBase64}
      logoWidth={60}
      logoHeight={60}
    />
  </div>

  {/* Text on the right */}
  <div className="flex flex-col items-start">
    <p className="text-center font-bold text-xl sm:text-left">PEMBAYARAN</p>
    <p className="text-center text-sm sm:text-left">a.n Alfinas Sandi</p>
    <p className="text-center text-sm sm:text-left">TOTAL BAYAR + ADMIN</p>
    <p className="text-center text-sm font-bold sm:text-left">
      {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(totalan)}
    </p>
  </div>
</div>

</>

  );
};

export default Payment;
